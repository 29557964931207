import React from 'react';
import styled from 'styled-components';
import { useTextClassNames } from './styles';

const StyledText = styled.span<Props>`
  ${(props) => (props.margin ? `margin: ${props.margin};` : '')}
`;

type TextProps =
  | React.HTMLAttributes<HTMLHeadingElement>
  | React.HTMLAttributes<HTMLParagraphElement>
  | React.HTMLAttributes<HTMLSpanElement>
  | React.HTMLProps<HTMLLabelElement>;

export type Variant =
  | 'display-1'
  | 'display-2'
  | 'display-3'
  | 'display-4'
  | 'display-5'
  | 'header-1'
  | 'header-2'
  | 'header-3'
  | 'header-4'
  | 'header-5'
  | 'header-6'
  | 'header-7'
  | 'body';

export type AsElement = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'p' | 'span' | 'label';

export type Props = TextProps & {
  /* Text */
  children?: React.ReactNode;

  /* HTML element that will be used to render (only applied for body) */
  as?: AsElement;

  /* Design variants */
  type?: Variant;

  /* Font-Size this will be only applied for body variant */
  size?: 'sm' | 'md' | 'lg' | 'xl';

  dataTestId?: string;

  margin?: string;
};

const Text = React.forwardRef<HTMLParagraphElement, Props>(({ children, type = 'body', ...props }, ref) => {
  const as = (() => {
    switch (type) {
      case 'display-1':
      case 'header-1':
        return 'h1';

      case 'display-2':
      case 'header-2':
        return 'h2';

      case 'display-3':
      case 'header-3':
        return 'h3';

      case 'display-4':
      case 'header-4':
        return 'h4';

      case 'display-5':
      case 'header-5':
      case 'header-6':
      case 'header-7':
        return 'h5';

      default:
        return 'span';
    }
  })();

  const styles = useTextClassNames({ ...props, type, children });

  return (
    <StyledText
      {...props}
      ref={ref}
      data-testid={props.dataTestId ?? undefined}
      as={props.as ? props.as : as}
      className={styles}
    >
      {children}
    </StyledText>
  );
});

Text.displayName = 'TEXT';

export default Text;
