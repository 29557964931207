import useLocalStorage from 'utils/src/hooks/useLocalStorage';
import useRouteState from 'utils/src/hooks/useRouteState';
import config from '@config';
import React from 'react';

import { useLocation } from 'react-router-dom';

type Value = [string | undefined, ((value: string) => void) | undefined];

const Context = React.createContext<Value | undefined>(undefined);
const localStorageKey = config.isOpsDash ? 'ops-dashboard-employer-id' : 'admin-dashboard-employer-id';

export const useCompanyPicker = (): Value => {
  const context = React.useContext<Value>(Context as unknown as React.Context<Value>);
  /**
   * @notes
   *  If context is undefined it means that it isn't within a OpsProvider, and instead of throwing an
   *  error we'll return undefined for both values (otherwise in admindash without ops it won't work)
   */
  if (context === undefined) {
    return [undefined, undefined];
  }

  return context;
};

const Provider = ({ children }: React.PropsWithChildren<unknown>) => {
  const location = useLocation();
  const [{ employerId }, setRouteState] = useRouteState<{ employerId?: string }>({ employerId: '' });
  const [localStorageValue, setLocalStorageValue] = useLocalStorage(localStorageKey, '');

  if (employerId && localStorageValue !== employerId) {
    setLocalStorageValue(employerId);
    window.location.reload();
  }

  React.useEffect(() => {
    if (localStorageValue && !employerId) {
      setRouteState({ employerId: localStorageValue });
    }
  }, [employerId, localStorageValue, setLocalStorageValue, setRouteState, location]);

  return <Context.Provider value={[localStorageValue, setLocalStorageValue]}>{children}</Context.Provider>;
};

export default Provider;
